<template>
  <tr class="bg-white">
    <slot></slot>
  </tr>
</template>
<style lang="css">
/* Only rows inside tbody will have a hover style. Rows inside thead will not have the style. */
tbody tr {
  @apply hover:bg-gray-50;
}
</style>
